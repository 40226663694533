.title-area {
    /* border-bottom: 1px solid rgba(5, 200, 255, 0.5); */
    border-bottom: 1px solid rgba(200, 200, 255, 0.5);
    margin: 0px;
    overflow: hidden;
    position: relative;
}

.title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-family: 'EliteDanger', sans-serif;
    padding: 50px;

    position: absolute;
    top: 0px;
}


.title-box div h2 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.4rem;
    margin: 0px;
    text-shadow: 1px 1px 4px #000000;
    font-weight: 200;
    padding-bottom: 10px;
  }
  

video {
  width: 100%;
  margin-top: -7%;
  margin-bottom: -7%;
  background-color: rgb(10,10,10);
}

.title-box div * {
  position: relative;
  text-align: center;
}

.title-box div h1 {
  color: rgba(255,255,255, 0.9);
  display: flex;
  font-size: 128px;
  position: relative;
  letter-spacing: -3px;
  text-shadow: black 0px 0px 100px, black 0px 0px 60px, black 0px 0px 60px, black 0px 0px 60px, black 0px 0px 6px;
  margin: 10px 0px;
  font-weight: 200;
}

.title-box div h1.mobile-header {
  font-size: 3rem;
  margin-top: 80px;
}

.title-box div h1 span {
    color: lightblue;
    display: inline;
    font-size: 30px;
    line-height: 128px;
    padding: 0px 30px;
}

#title-box-offset-container-div {
  margin-top: -100px;
}

.signup-button-container::before,.signup-button-container::after {
  border: 1px solid rgba(236,232,225,.5);
  content: "";
  display: block;
  position: absolute;
  height: calc(50% - 0.4rem);
  width: 100%;

  left: 0;
}

.signup-button-container:hover {
  text-decoration: underline;
}

.signup-button-container::before {
  border-bottom: none;
  top: 0;
}

.signup-button-container::after {
  border-top: none;
  bottom: 0;
}

.signup-button-container {
  position: relative;
  width: auto;

  margin-top: 60px;
  
  padding:7px;
}

.signup-button-container:hover {
    filter: brightness(1.1);
}

.title-box div.email-link {
  font-weight: bold;
  border: none;
  text-decoration: inherit;
  display: inline-block;

  color: white;

  font-size: 0.9rem;
  font-weight: bold;
  background-color: rgb(255, 70, 85);
  font-family: 'Electrolize', sans-serif;

  padding: 16px 68px;
  position: relative;
}

.title-box div.email-link .link-square {
  background: rgb(30,20,20);
  width: 2px;
  height: 3px;
  position: absolute;
}

.title-box div.email-link .link-square.tl {
  top: 0;
  left: 0;
}

.title-box div.email-link .link-square.br {
  bottom: 0;
  right: 0;
}