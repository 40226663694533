.body-section {
    color: rgb(255, 255, 255);
    /* background: linear-gradient(rgba(0, 32, 64, 0.5), rgba(0, 0, 0, 1)); */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(26, 11, 0, .7));
    padding-bottom: 200px;
}

.body-section h2 {
    padding-top: 95px;
    max-width: 850px;
    margin: 0px auto;
    font-size: 2.2rem;
    /* text-shadow: black -3px -3px; */
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 40px;
    font-weight: 200;
    font-family: 'Kanit', sans-serif;

}

.body-section h2.section-header { 
    padding-top: 70px;
    font-size: 3Frem;
    border: none;
    padding-bottom: 0;
    font-family: 'Electrolize', sans-serif;
}

.body-section p {
    font-size: 20px;
    font-family: 'Kanit', sans-serif;
}

.body-section .feature-list {
    margin-left: -30px;
    max-width: 750px;
    margin: auto;
    padding: 35px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.body-section .feature-list ul {
    overflow: hidden;
    font-family: 'Kanit', sans-serif;
    font-weight: 200;
    font-size: 22px;
    max-width: 750px;
    margin: auto;
}

.body-section .feature-list li {
    list-style-position: inside;
    display: inline;
    padding: 0px 20px 0px 0px;
}

.container.battle-items {
    padding-left: 0px;
    padding-right: 0px;
}

.battle-item {
    text-align: left;
    width: 100%;
    height: 150px;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), transparent);
    border-left: 5px solid rgb(43, 195, 255);
    margin-bottom: 30px;
}

.battle-item .battle-item-icon {
    height: 100%;
    display: inline-block;
}

.battle-item p {
    font-family: 'Kanit', sans-serif;
    display: inline-block;
    padding-left: 20px;
}

.battle-item img {
    height: 100%;
    border-right: 1px solid rgba(0, 195, 255, 0.4);
}

.battle-item .gradient-border{
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 195, 255, 0.5), transparent 75%);
    position: relative;
}

.battle-item .gradient-border.bottom{
    top: -1px;
}

.discord-link :hover { 
    filter: brightness(1.3);
}

.discord-link {
    padding: 10px 35px;
    font-size: 0.8rem;
    font-weight: bold;
    
    border: none;
    text-decoration: none;
    display: inline-block;

    background-color: rgb(88, 101, 242);
    font-family: 'Electrolize', sans-serif;
    color: white;
    border: 1px solid black;
    
    position: relative;
}

.discord-link:hover {
    color: white;
    filter: brightness(1.15);
}

#discord-link-container {
    margin-top: 60px;
    
    position: relative;
    padding: 7px;
    /* background-color: red; */
    width: auto;
    display: inline-block;
}

#discord-link-container:before,#discord-link-container::after {
    border: 1px solid rgba(236,232,225,.5);
    content: "";
    display: block;
    position: absolute;
    height: calc(50% - 0.4rem);
    width: 100%;
  
    left: 0;

    pointer-events: none;
  }
  
  #discord-link-container::before {
    border-bottom: none;
    top: 0;
  }
  
  #discord-link-container::after {
    border-top: none;
    bottom: 0;
  }