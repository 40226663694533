#header {
    height: 50px;
    border-bottom: 1px solid rgba(5, 200, 255, 0.5);
    background-color: rgb(5, 15, 15);
    box-shadow: inset 0 0 15px 2px rgba(1,50,155,.25);
    text-align: left;
    position: static;
    top: -50px;
    z-index: -10;
    padding: 0 30px;
}

#header .item {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    color: white;
    font-family: "EliteDanger", sans-serif;
    font-size: 30px;
    font-weight: bold;
}

#header a {
    color: inherit;
    text-decoration: none;
}