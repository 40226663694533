

.role-cards{
    margin-top: 30px;
  }

  .role-cards .col-md-5 {
    padding: 0px;
  }
  
  .role-card {
    height: 550px;
    margin: auto;
    /* border: 1px solid rgba(255,255,255, 0.25); */
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-top: 30px;
  }
  
  .role-card-img {
    width: 100%;
    height: 250px;
    background-size: cover;
    /* box-shadow: inset 0 0 15px 2px rgba(1,155,255,.5), inset 0 0 30px rgba(100,155,255,.1); */
    border: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 15px;
  }
  
  .role-card-img-inner {
    background: repeating-linear-gradient( transparent, transparent 6px, rgba(0, 0, 0, .15) 2px, rgba(0, 0, 0, .15) 8px );
    width: 100%;
    height: 100%;
    position: relative;
    animation: scrollbg 1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 50px;
    text-shadow: 2px 2px 15px black, 2px 2px 15px black, 2px 2px 15px black;
  }
  
  @keyframes scrollbg {
    to {
      background-position-y: 0;
    }
    to {
      background-position-y: 8px;
    }
  }
  
  .role-card p {
    text-align: left;
    padding: 0px 10px;
    font-weight: normal;
    color: rgba(255, 253, 208, 0.9);
  }