#privacy-policy {
    width: 60%;
    margin: 50px auto;
    min-height: 100vh;
    background-color: rgb(255,255,255, 0.95);
    border-radius: 10px;
    border: 1px solid black;
    padding: 50px;
}

#privacy-policy {
    text-align: left;
}

#privacy-policy .center {
    text-align: center;
}

#privacy-policy p {
    text-align: left;
}

#privacy-policy table, th, td {
    border: 1px solid black;
    text-align: center;
    padding: 15px;
}