#signup-modal {
    text-align: center;
    width: 500px;
    margin: 200px auto 0px auto;
    max-width: 80%;
}

#signup-modal #mce-EMAIL {
    padding: 8px;
    width: 70%;
    font-weight: bold;
}