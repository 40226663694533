* {
  box-sizing: border-box;
}

html {
  background-color: black;
}

#root {
  background-attachment: fixed;
  overflow: hidden;
}

.App {
  text-align: center;
  font-family: 'Electrolize', sans-serif;
  
}

.panel {
  border: 1px solid rgb(5,160,190);
  width: 1400px;
  margin: auto;
  background-color: rgba(3,10,20, 0.9);

  border-radius: 3px;
  animation: open .2s linear;
}

@keyframes open {
  from {
    transform: scaleX(0.5);
    filter: opacity(0);
  }
  to {
    transform: scaleX(1);
    filter: opacity(1);
  }
}

@font-face {
  font-family: 'EliteDanger';
  src: url('/elitedanger.ttf');
}