#mc_embed_signup {
    /*height: 350px;*/
    padding: 0px 5px 65px 5px;
    background-color: rgba(20, 20, 20, .5);
    color: rgba(255, 255, 255, 0.75);
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid rgba(5, 200, 255, 0.5);
}

#mc_embed_signup h2 {
    font-size: 3rem;
    font-weight: bolder;
    margin-bottom: 20px;
    /*color: deepskyblue;*/
}

#mc_embed_signup h3 {
    font-weight: normal;
    font-style: italic;
    font-size: 15px;
    color: rgb(170, 170, 170);
    margin-bottom: 15px;
}

#mc_embed_signup h3 .letter {
    font-style: normal;
}

#mc_embed_signup .email {
    padding: 10px;
    border-radius: 10px;
    width: 75%;
    max-width: 500px;
}

#mc-embedded-subscribe {
    background-color: rgb(255, 70, 85);
    color: white;
    border: none;
    padding: 10px 30px;
    cursor: pointer;

    font-weight: bold;
    border-radius: 3px;

    position: relative;
    z-index: 1;
}

#mc-embedded-subscribe-outer{
    padding: 7px;
    margin-top: 20px;
    
    display: inline-block;
    position: relative;
}

#mc-embedded-subscribe-outer::before,#mc-embedded-subscribe-outer::after {
    border: 1px solid rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    position: absolute;
    height: calc(50% - 0.4rem);
    width: 100%;

    left: 0;
    pointer-events: none;
}

#mc-embedded-subscribe-outer::before {
    border-bottom: none;
    top: 0;
}
  
#mc-embedded-subscribe-outer::after {
    border-top: none;
    bottom: 0;
}